import React, { useState, useEffect } from 'react'
import axios from 'axios';
import VillasCard from '../Common/VillasCard';
import { useNavigate } from "react-router-dom";

function VillasPage() {

  const [customTravel, setCustomTravelState] = useState([]);

  // Define an async function to fetch data
  const getData = async () => {
    try {
      const response = await axios.get("https://api.travoolah.com/api/Villa");
      // Assuming the data is an array, set it directly
      // console.log("resp" , response);
      setCustomTravelState(response.data.$values);
      // console.log(response.data.$values.images.$values.replace(/\\/g, '/'));
      // ${event.images.$values[0].replace(/\\/g, '/')}

    } catch (error) {
      console.error("Error fetching adventure data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleCardClick = (id) => {
    navigate(`/villas/${id}`)
  }


  return (
    <div className='flex flex-col py-5'>
      <div className='text-center'>
        <h3 className='text-[#353535] text-2xl md:text-3xl font-semibold px-5 py-5'>Hotels</h3>
      </div>
      <div className='grid grid-cols md:grid-cols-2 lg:grid-cols-3 gap-6 px-5 py-5'>
        {/* <Card img={Lalit} title={"The Lalit Grand Palace"} address={"Gupkar Rd, Srinagar, J&K 190001"} oldPrice={"7999"} newPrice={"4999/Night"}/> */}
        {customTravel.length > 0 ?
          customTravel.map((event, index) => (
            <div key={index} className='flex-none w-[280px] sm:w-[320px] snap-start'>
              <VillasCard
                img={
                  event.images && event.images.$values && event.images.$values.length > 0
                    ? `https://api.travoolah.com/${event.images.$values[0].replace(/\\/g, '/')}`
                    : '/placeholder-image.jpg'
                }
                title={event.title}
                duration={event.duration}
                oldPrice={`${event.price + 1500}`}
                newPrice={event.price}
                address={event.description}
                onClick={() => {
                  handleCardClick(event.id)
                }}
              />
            </div>
          )) : <p>No data avalible</p>}
      </div>
    </div>
  )
}

export default VillasPage